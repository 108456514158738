
export default {
    'intel_product': 'Intelligent Design Products',
    'developing_products': 'Products in Development',
    'typical_cases': 'Typical Cases',
    'HemuNews': 'Hemu News',
    'about_hemu': 'Hemu AI-structure',
    'about_us': 'About us',
    'language': 'language',
    'sign_up': 'Sign up/Sign in',
    'shearwall_structure': 'Shearwall Structure',
    'frame_structure': 'Frame Structure',
    'core_structure': 'Frame-Core Tube Structure',
    'structure_intel': 'Intelligent structure design',
    'watch_video': 'Watch the video',
    'production_and_solution': 'Production and solution',
    'local_assistant': 'Copilot-local intelligent design assistant',
    'local_assistant_content': 'AIstructure-Copilot is an intelligent design assistant based on the CAD platform.',
    'platform_cloud': 'AI design project management Platform',
    'platform_cloud_content': 'AIstructure Platform is a web-based platform for intelligent project design and management.',
    'shearwall_structure_design_title': 'Intelligent design of the shearwall structure',
    'shearwall_structure_design_content': 'Based on generative AI technologies such as Generative Adversarial Networks (GANs) and Graph Generation Networks, the AI automatically generates corresponding shear wall structure layout designs from the input architectural floor plans.',
    'frame_structure_design_title': 'Intelligent design of the frame structure',
    'look_forward': 'Coming soon',
    'core_structure_design_title': 'Intelligent design of frame-core tube structure',
    'core_structure_desgin_content': 'Based on generative AI technologies such as Generative Adversarial Networks (GANs), the AI automatically generates section size designs of the corresponding structural component  from the input framework-core tube structure layout plans.',
    'key_component': '',
    'key_component_content1': '"Data-Mechanics-Knowledge" Coupled',
    'key_component_content2': 'Driven Intelligent Generative Design Algorithms',
    'patent': 'Patents',
    'news': 'News',
    'more_news': 'View more information',
    'known_more': 'learn more',
    'product_problem': 'Product issue',
    'contact_email': 'Contact email: ',
    'cooperate': 'Cooperate',
    'communication_group': 'Communication group',
    'QQ_group': 'QQ group: ',
    'case_number': 'Case number: ',
    'suggest_chrome': 'We recommend using the Chrome browser to browse this website for a better experience',
    'product_download': 'Product download',
    'intel_product_content1': 'AIstructure-Copilot is an intelligent design assistant for building structures based on the generative AI and CAD platform.',
    'intel_product_content2': 'Install the AIstructure.exe program on your local computer. The program will automatically create its Copilot based on the locally installed CAD platform (currently recommending Autodesk CAD and Tianzheng CAD). With AIstructure-Copilot, you can achieve intelligent shear wall structure design within the CAD platform on your local computer.',
    'intel_platform': 'AI Design Project Management',
    'intel_platform_tips': ' (Supports Trial of Typical Cases,  Chinese & English)',
    'sign_in': 'Sign in',
    'instruction': 'Instructions for use',
    'intel_platform_content1': 'The intelligent design project management platform features shear wall component design, beam-slab component design, and framework-core tube component design. It intelligently processes the uploaded data and architectural pixel images to generate structural pixel images, which are then imported into the original architectural CAD.',
    'intel_platform_content2': 'Currently, shear wall component design is recommended for use. The beam-slab component design and framework-core tube component design modules are still being improved and tested. We welcome you to try them out.',
    'automated_rule_interpretation': '',
    'automated_rule_interpretation_content1': 'If computers could understand design standards in the field of civil engineering and automatically translate them into computer code, it would significantly enhance the level of intelligence in our structural design.',
    'automated_rule_interpretation_content2': 'In response, we developed an AI rule automatic interpretation module. When a user inputs sentences in natural language, the AI can understand the meaning of the words, tag the text, and then translate the content into a corresponding syntax tree structure, which can be converted into code in any computer language.',
    'enter_automated': 'Enter the automatic interpretation interface',
    'input': 'Input',
    'interpretation': 'interpret',
    'input_example': 'You can try to enter the following rules for example (only in Chinese):',
    'contact_person': 'Contact: ',
    'case_intro': 'Case introduction',
    'input_pixel_diagram': 'Input pixel diagram',
    'click_to_download_data': 'Download vector data',
    'engineer_design_pixel_diagram': 'Engineer design pixel diagram',
    'StructGAN_design_pixel_diagram': 'StructGAN design pixel diagram',
    'StructGAN_design_model': 'StructGAN design analysis model',
    'click_download': 'download',
    'download': 'download',
    'input_data': 'Input data',
    'engineer_design': 'Engineer design',
    'StruGAN_design': 'StructGAN design',
    'company_intro': 'Company Profile',
    'company_intro_content1': 'Hemu AI-structure was established in March 2022, focusing on improving the inefficiencies in the construction industry and empowering the digitalization and intelligence of civil engineering.',
    'company_intro_content2': 'Hemu AI-structure has independently developed key intelligent design technologies based on design drawings, design conditions, design standards, structural mechanics, and other data-driven and AI for Science approaches. The company has obtained a series of patents covering various key design elements, resulting in a generative intelligent design product for building structures that incorporates experience learning and mechanical analysis capabilities. This technology can automatically complete structural design tasks that meet complex standard requirements. The material quantities in AI-designed structures are similar to those designed by engineers, and the technology significantly reduces building structure design time by over tenfold and decreases the workload of engineers by more than 50%.',
    'company_intro_content3': 'In the future, it will bring a new design paradigm and production model to architectural engineering, as well as to new infrastructure, transportation, and energy construction.',
    'hemu_team': 'Hemu Team',
    'hemu_team_content': 'Structural Design + AI Empowerment. Led by a professor from the Department of Civil Engineering at Tsinghua University and co-founded with renowned experts in the structural field, it forms a top-tier research team combined with an experienced industry team.',
    'history': 'History',
    'awards': 'Awards',
    'basic_info': 'Fill in basic information',
    'back_top': 'Back to top',
    'contact_now': 'Contact now',
    'name': 'Name',
    'input_name': 'Please enter your name',
    'unit': 'Affiliation',
    'input_unit': 'Please enter your affiliation',
    'email': 'Email',
    'input_email': 'Please enter your email',
    'contact_phone': 'Contact phone',
    'input_phone': 'Please enter your phone number',
    'question_detail': 'Question details',
    'input_question_detail': 'Please enter the details of your question',
    'other_suggestion': 'Other questions or suggestions',
    'input_suggestion': 'Please enter your suggestion',
    'submit': 'Submit',
    'reset': 'Reset',
    'product_please_contact': 'For product-related issues, please contact:',
    'hemu_please_contact': 'If you are interested in Hemu products/solutions or intend to cooperate in other ways, please contact:',
    'name_length_limit': 'Length should be between 2 and 5 characters',
    'incorrect_email': 'Incorrect email format',
    'incorrect_phone': 'Incorrect phone number format',
    'initial_page': 'Home',
    'main_business': 'Main Business',
    'business_content': 'Hemu AI Structure is a technology company established in March 2022, targeting the civil engineering field to provide digital, intelligent, and related services to the industry. Currently, intelligent design products have been launched on the official website in July 2023. So far, more than 700 users have registered and used it, completing over 1800 cases of shear wall residential design. The company provides the following services:',
    'business_content1': 'Ⅰ. Personalized product deployment (e.g., provide private cloud deployment and local server deployment services)',
    'business_content2': 'Ⅱ. Customized R&D  (e.g., generative AI design in related disciplines, personalized data training.)',
    'business_content3': 'Ⅲ. Cooperative research (e.g., upstream and downstream software cooperation)',
    'quality_and_award': 'Qualifications & Awards',
    'quality': 'Innovative Small and Medium-sized Enterprises, Beijing Science and Technology Small and Medium-sized Enterprises',
    'coopetration': 'Business Cooperation',
    'wechat': '(Same WeChat ID)',
    'research_process': 'Research History',
    // 登录注册页面
        "back_to_main": "Back to Main",
        "system_update_tips": "Due to system update, users registered before March 29, 2024, please change your password in the 'Forgot Password' upon login.",
        "hello": "Hello",
        "please_sign_in": "Please Sign In",
        "click_to_sign_up": "Don't have an account? Click to Sign Up",
        "phone_number": "Phone Number",
        "password": "Password",
        "forget_password": "Forgot Password",
        "sign_in_now": "Sign In Now",
        "sign_in_by_code": "Sign In by Code",
        "please_sign_up": "Welcome to Sign Up",
        "account_name": "Account Name (letters, numbers, underscore):",
        "enter_english_account_name": "Enter Account Name in letters, numbers, underscore",
        "chinese_name": "User Name:",
        "enter_chinese_name": "Enter User Name",
        "unit_name": "Affiliation:",
        "enter_unit_name": "Enter Affiliation Name",
        "enter_phone_number": "Enter Phone Number",
        "enter_password": "Enter Password",
        "confirm_password": "Confirm Password:",
        "enter_password_again": "Enter Password Again",
        "sex": "Gender:",
        "male": "Male",
        "female": "Female",
        "phone_code": "Phone Code:",
        "code": "Code",
        "get_code": "Get Code",
        "sign_up_now": "Sign Up Now",
        "account_sign_in_now": "Already have an account? Sign In Now",
        "reset_password": "Reset Password",
        "enter_6_code": "Enter 6-digit Code",
        "change_now": "Change Now",
        "back_to_sign_in": "Back to Sign In",
        "sign_in_by_password": "Sign In by Password",
        "enter_correct_phone_number": "Enter correct phone number format",
        "correct_password_format": "Password must be 6-15 characters long",
        "correct_code_format": "Code must be 6 characters long",
        "correct_chinese_name_format": "The username must be the real name",
        "correct_english_name_format": "English name must be 3-11 characters long, alphanumeric, and contain at least one letter",
        "choose_sex": "Please choose gender",
        "sign_in_success": "Sign Up successful, redirecting to Sign In page, please login again!",
        "uncorrect_code": "Incorrect or expired code!",
        "update_success": "Update successful, please log in again!",
        "login_failed_tips": "User, phone number, or password validation failed, please re-enter or contact develop group",
        "login_success_tips": "Login successful",
        "sign_in_by_phone": "Sign In by Phone",
        "sign_in_by_email": "Sign In by Email",
        "email_code": "Email Code",
        "login_phone": "Phone Login",
        "login_email": "Email Login",
        "input_correct_email_format": "Please enter correct email format",
        "password_login": "Password Login",
        "login_number": "Username/Phone Number/Email",
        "email_code_login": "Email Code Login",
        "phone_code_login": "Phone Code Login",
        "reset_phone_password": "Reset Phone Password",
        "reset_email_password": "Reset Email Password",
    // 云平台页面
        "intelli_design": "Intelligent Design",
        "change_password": "Change Password",
        "admin_page": "Admin Page",
        "log_out": "Log Out",
        "my_project": "My Project",
        "shearwall_design": "Shear Wall Design",
        "frame_design": "Frame Structure Design",
        "frame_core_desgin": "Frame - Core Tube Design",
        "cad_plugin": "CAD Pre/Post Processing Plugin",
        "tool_download": "Parametric Modeling Tool Download",
    // 新建剪力墙项目
        "enter_project_title": "Enter Project Title",
        "shearwall_project": "Shear Wall Project",
        "import_project": "Import Project",
        "creat_project": "Create Project",
        "delete": "Delete",
        "add_shearwall_design": "Add Shear Wall Design",
        'add_shearwall_tips': '（Please download the input architectural data (.json) from the typical cases for a trial）',
        "project_name": "Project Name",
        "vector_data": "Input Architectural Data",
        "click_upload": "Click to Upload",
        "component_img": "Building Component Image",
        "space_img": "Building Space Image",
        "cancel": "Cancel",
        "confirm": "Confirm",
        "correct_project_title_format": "Project name must be letters, numbers, or underscore",
        "choose_component_img": "Please choose building component image",
        "choose_space_img": "Please choose building space image",
        "choose_json_file": "Please choose a file in JSON format",
    // 剪力墙项目详情
        "status": "Status",
        "designed": "Designed",
        "undesigned": "Undesigned",
        "input_archi": "Input Architectural Design Image",
        "design_button_tips": "Click design button to get structural design",
        "design": "Design",
        "shearwall_pic_instruct": "Shear Wall - Beam Structure Design (Red axis lines represent shear walls, blue axis lines represent beams)",
        "diffusion_model": "Structural Design by Diffusion",
        "to_be_designed": "To Be Designed",
        "material_predict": "Material Usage Intelligent Prediction",
        "GAN_pic": "Structural Design by GAN",
        "GNN1_pic": "Structural Design by GNN1",
        "GNN2_pic": "Structural Design by GNN2",
        "remind": "Reminder",
        "delete_confirm_tips": "This action will permanently delete the project, continue?",
        "delete_success": "Delete successful!",
        "delete_failed": "Delete failed!",
        "delete_canceled": "Deletion canceled",


        'AIstructure-Copilot': 'AIstructure-Copilot',
        'AIstructure-Copilot_tips': ' (Local CAD Plugin, Chinese Only)',

        'software_copyrights': '8 software copyrights',
        'case1_title': "Case 1 Introduction:",
        'case1_tips': "The building's structural height is 75 m, with a story height of 3 m and a total of 25 floors. The seismic design intensity is 8 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.2 g), and the site characteristic period is 0.4 s.",
        'case2_title': "Case 2 Introduction:",
        'case2_tips': "The building's structural height is 81 m, with a story height of 3 m and a total of 27 floors. The seismic design intensity is 7 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.1 g), and the site characteristic period is 0.45 s.",
        'case3_title': "Case 3 Introduction:",
        'case3_tips': "The building's structural height is 54 m, with a story height of 3 m and a total of 18 floors. The seismic design intensity is 7 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.1 g), and the site characteristic period is 0.35 s.",
        'case4_title': "Case 4 Introduction:",
        'case4_tips': "The building's structural height is 54 m, with a story height of 3 m and a total of 18 floors. The seismic design intensity is 6 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.05 g), and the site characteristic period is 0.45 s.",
        'case5_title': "Case 5 Introduction:",
        'case5_tips': "The building's structural height is 78 m, with a story height of 3 m and a total of 26 floors. The seismic design intensity is 7 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.1 g), and the site characteristic period is 0.4 s.",
        'case6_title': "Case 6 Introduction:",
        'case6_tips': "The building's structural height is 54 m, with a story height of 3 m and a total of 18 floors. The seismic design intensity is 6 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.05 g), and the site characteristic period is 0.45 s.",
        'case7_title': "Case 7 Introduction:",
        'case7_tips': "The building's structural height is 54 m, with a story height of 3 m and a total of 18 floors. The seismic design intensity is 6 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.05 g), and the site characteristic period is 0.45 s.",
        'case8_title': "Case 8 Introduction:",
        'case8_tips': "The building's structural height is 99 m, with a story height of 3 m and a total of 33 floors. The seismic design intensity is 7 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.1 g), and the site characteristic period is 0.35 s.",
        'case9_title': "Case 9 Introduction:",
        'case9_tips': "The building's structural height is 51 m, with a story height of 3 m and a total of 17 floors. The seismic design intensity is 7 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.1 g), and the site characteristic period is 0.4 s.",
        'case10_title': "Case 10 Introduction:",
        'case10_tips': "The building's structural height is 51 m, with a story height of 3 m and a total of 17 floors. The seismic design intensity is 7 degrees (with a peak ground acceleration (PGA) of 10% exceedance in 50 years equaling 0.1 g), and the site characteristic period is 0.4 s.",

        'downloadJOSN': 'Download input architectural design data (.json)',
        'case_legend': '*Legend: Gray lines represent architectural walls, green lines represent doors and windows, red lines represent shear walls, and blue lines represent beams.',

        "archi_floor": "Architectural plan design",
        "GAN_floor": "Structural design by GAN",
        "GNN1_floor": "Structural design by GNN1",
        "GNN2_floor": "Structural design by GNN2",
        "Diffusion_floor": "Structural design by Diffusion",
        'enter_cloud': 'Enter AI design platform'
}