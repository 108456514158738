import { request, request2 } from '@/utils/request'

export const newsAPI = () => {
  return request({
    url: '/selectNews'
  })
}
export const benchAPI = () => {
  return request({
    url: '/selectCase'
  })
}

export const historyAPI = () => {
  return request({
    url: '/History'
  })
}

export const awardAPI = (language) => {
  return request({
    url: '/Awards',
    headers: {
      'language': language
    }
  })
}

export const literAPI = () => {
  return request({
    url: '/selectPatent'
  })
}

// 主页视频
export const homeViewUrl = () => {
  return request({
    url: '/downloadMedia'
  })
}
// 获取下载智能云平台使用说明url
export const downloadGANIO =() => {
  return request({
    url: 'downloadGANIO'
  })
}
// 获取下载copilot产品url
export const downloadAIstructure =() => {
  return request({
    url: 'downloadAIstructure'
  })
}
// 获取下载参数化建模工具url
export const downLoadPMT =() => {
  return request({
    url: 'downLoadPMT'
  })
}

//登录
export const loginAPI = ({ loginId, password }) => {
  return request2({
    url: '/login/userlogin',
    method: 'POST',
    data: {
      loginId,
      password
    }
  })
}

//注册验证码  注册，验证码登录
export const sendPhoneCode = (obj) => {
  return request2({
    url: '/login/sendPhoneCode/' + obj.mobile,
    method: 'GET'
  })
}

// 注册请求
export const regAPI = (data) => {
  return request2({
    url: '/login/register',
    method: 'POST',
    data
  })
}

// 验证码登录
export const codeAPI = (obj) => {
  return request2({
    url: '/login/uucsSmsLogin/' + obj.phone + '/' + obj.verifyCode,
    method: 'GET',
  })
}
//验证码登录
export const uucsSmsLogin = (obj) => {
  return request2({
    url: '/login/sendPhoneCode/' + obj.phone,
    method: 'GET'
  })
}
// 忘记密码密钥id
export const smsCodeTold = (data) => {
  // return request2({
  //   url: '/login/smsCodeToId/' + data.phone + '/' + data.code,
  //   method: 'GET'
  // })
  return request2({
    url: '/login/forgetPassword',
    data: {mobile: data.phone, phoneVerifyCode: data.code, newPassword: data.newPassword},
    method: 'POST'
  })
}


//添加 剪力墙设计
export const addShearwallAPI = ({ prjName, scale, seismic, structure, condition5, condition6, condition7, condition8, condition9, condition10 }, data) => {
  // return request2({
  //   url: '/upload/inwall',
  //   method: 'POST',
  //   params: {
  //     prjName,
  //     scale,
  //     seismic,
  //     structure
  //   },
  //   data,
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //     // uername:
  //     'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写  
  //   }
  // })
  return request2({
    url: '/upload/inwall',
    method: 'POST',
    params: {
      prjName,
      scale,
      seismic,
      structure,
      condition5,
      condition6,
      condition7,
      condition8,
      condition9,
      condition10
    },
    data,
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      // uername:
      'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写  
    }
  })
}
// 剪力墙数据
export const shearwallDataAPI = (pageNum, pageSize, content) => {
  // return request2({
  //   url: '/find/walldata?pageNum=' + pageNum + '&pageSize=' + pageSize,
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/find/walldata?pageNum=' + pageNum + '&pageSize=' + pageSize + '&content=' + content,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 搜索剪力墙项目
export const searchShearwallAPI = (pageNum, pageSize, content) => {
  return request2({
    url: '/find/walldata?pageNum=' + pageNum + '&pageSize=' + pageSize + '&content=' + content,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 删除剪力墙
export const deleteShearWall = (id) => {
  // return request2({
  //   url: '/wall/delete/deleteWall?id=' + id,
  //   method: 'POST',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/wall/delete/deleteWall?id=' + id,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 编辑剪力墙--回显数据
export const updateShearWall = (id) => {
  // return request2({
  //   url: '/wall/update/findOneWall?id=' + id,
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/wall/update/findOneWall?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}


// 编辑剪力墙--点击保存
export const updateShearWall2 = ({ prjName, scale, id, seismic, structure, condition5, condition6, condition7, condition8, condition9, condition10 }, data) => {
  // return request2({
  //   url: '/wall/update/updateWall',
  //   method: 'POST',
  //   params: {
  //     userName,
  //     inTxtUrl,
  //     inPngUrl,
  //     uploadTime,
  //     txtFileName,
  //     pngFileName,
  //     prjName,
  //     scale,
  //     id,
  //     seismic,
  //     structure
  //   },
  //   data,
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //     'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写  
  //   }
  // })
  return request2({
    url: '/wall/update/updateWall',
    method: 'POST',
    params: {
      prjName,
      scale,
      id,
      seismic,
      structure, condition5, condition6, condition7, condition8, condition9, condition10
    },
    data,
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写  
    }
  })
}

// 下载剪力墙的输出图片
export const outWallPngFileAPI = ({ id, x, y }) => {
  return request2({
    url: '/download/outWallPngFile',
    method: 'POST',
    params: {
      id,
      x,
      y
    },
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/json"
    }
  })
}

// 下载剪力墙的Gdt文件
export const outWallGdtFileAPI = ({ id, x, y }) => {
  return request2({
    url: '/download/outWallGdtFile',
    method: 'POST',
    params: {
      id,
      x,
      y
    },
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/octet-stream"
    }
  })
}

// 复制剪力墙项目  /find/copywalldata
export const copywalldataAPI = (id) => {
  return request2({
    url: '/find/copywalldata?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 剪力墙下载
// export const downloadShearwallAPI=(fileName)=>{
//   return request2({
//     url:'/download/wallfile',
//     method:'GET',
//     params:{
//       fileName
//     },
//     headers: {
//       "token": localStorage.getItem('token'),
//       "username":localStorage.getItem('username'),
//     }
//   })
// }

// 剪力墙转换设计
export const changeShearwallAPI = (id, pngFileName) => {
  // return request2({
  //   url: '/transform/inwall',
  //   method: 'GET',
  //   params: {
  //     id,
  //     pngFileName
  //   },
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/transform/inwall',
    method: 'GET',
    params: {
      id,
      pngFileName
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}


// 梁板数据
export const getBeanDataAPI = (pageNum, pageSize, content) => {
  return request2({
    url: '/beam/find/beamdata',
    method: 'GET',
    params: {
      pageNum,
      pageSize,
      content
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 搜索梁板项目
export const searchBeamAPI = (pageNum, pageSize, content) => {
  return request2({
    url: '/beam/find/beamdata?pageNum=' + pageNum + '&pageSize=' + pageSize + '&content=' + content,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

//添加 梁板设计
export const addBeamAPI = ({ prjName, beamDesignType, beamLong, beamLow, beamUp, condition6, condition7, condition8, condition9, condition10 }, data) => {
  return request2({
    url: '/beam/upload/uploadBeam',
    method: 'POST',
    params: {
      beamDesignType,
      condition6, condition7, condition8, condition9, condition10,
      prjName,
      beamLong,
      beamLow,
      beamUp
    },
    data,
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      // uername:
      'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写  
    }
  })
}
// 删除梁板
export const deleteBeamAPI = (id) => {
  return request2({
    url: '/beam/delete/deleteBeam?id=' + id,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 复制梁板项目 /beam/find/copybeamdata
export const copybeamdataAPI = (id) => {
  return request2({
    url: '/beam/find/copybeamdata?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 编辑梁板时回显数据
export const findOneBeamAPI = (id) => {
  return request2({
    url: '/beam/update/findOneBeam?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

//修改梁板数据
export const updateBeamAPI = ({ prjName, beamDesignType, id, beamLong, beamUp, beamLow, condition6, condition7, condition8, condition9, condition10 }, data) => {
  // return request2({
  //   url: '/beam/update/updateBeam',
  //   method: 'POST',
  //   params: {
  //     prjName,
  //     id,
  //     wallOutPngUrl,
  //     beamInPngUrl,
  //     beamUploadTime,
  //     wallUploadTime, txtFileName,
  //     pngFileName,
  //     userName,
  //     beamLong,
  //     beamUp,
  //     beamLow
  //   },
  //   data,
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //     'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写
  //   }
  // })
  return request2({
    url: '/beam/update/updateBeam',
    method: 'POST',
    params: {
      prjName,
      id,
      beamDesignType,
      beamLong,
      beamUp,
      beamLow,
      condition6,
      condition7,
      condition8,
      condition9,
      condition10
    },
    data,
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写
    }
  })
}


// 梁板设计转换
export const transformBeamAPI = (id, pngFileName) => {
  // return request2({
  //   url: '/beam/transform/inbeam',
  //   params: {
  //     id,
  //     pngFileName
  //   },
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/beam/transform/inbeam',
    params: {
      id,
      pngFileName
    },
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}


// 下载梁板输出图片
export const outBeamPngFileAPI = ({ id, x, y }) => {
  return request2({
    url: '/beam/download/outBeamPngFile',
    method: 'POST',
    params: {
      id,
      x,
      y
    },
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/json"
    }
  })
}

// 下载梁板的Gdt文件
export const outBeamGdtFileAPI = ({ id, x, y }) => {
  return request2({
    url: '/beam/download/outBeamGdtFile',
    method: 'POST',
    params: {
      id,
      x,
      y
    },
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/octet-stream"
    }
  })
}

// 获取框架核心筒数据
export const horralDataAPI = (pageNum, pageSize, content) => {
  // return request2({
  //   url: '/corebute/list?pageNum=' + pageNum + '&pageSize=' + pageSize,
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/corebute/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&content=' + content,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 搜索框架核心筒项目
export const searchHorralAPI = (pageNum, pageSize, content) => {
  return request2({
    url: '/corebute/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&content=' + content,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 创建一个框架核心筒
export const addHorralAPI = ({ title, seismicIntensity, earthquakeGroup, sceneCategory, windPressure, groundRoughness, structureHeight, standardFloorStart, standardFloorEnd, showFileName }, data) => {
  // return request2({
  //   url: '/corebute/uploadCoreBute',
  //   params: {
  //     title,
  //     seismicIntensity,
  //     earthquakeGroup,
  //     sceneCategory,
  //     windPressure,
  //     groundRoughness,
  //     structureHeight,
  //     standardFloorStart,
  //     standardFloorEnd,
  //     showFileName
  //   },
  //   data,
  //   method: 'POST',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //     'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写
  //   }
  // })
  return request2({
    url: '/corebute/uploadCoreBute',
    params: {
      title,
      seismicIntensity,
      earthquakeGroup,
      sceneCategory,
      windPressure,
      groundRoughness,
      structureHeight,
      standardFloorStart,
      standardFloorEnd,
      showFileName
    },
    data,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写
    }
  })
}

// 复制框架核心筒项目
export const copyCorebuteDataAPI = (id) => {
  return request2({
    url: '/corebute/copyCorebuteData?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 删除框架核心筒
// /corebute/corebutedelete
export const deleteCoreHorralAPI = (id) => {
  // return request2({
  //   url: '/corebute/corebutedelete?id=' + id,
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/corebute/corebutedelete?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 编辑框架核心筒
// export const deleteCoreHorralAPI = (id) => {
//   return request2({
//     url: '/corebute/corebutedelete?id=' + id,
//     method: 'GET',
//     headers: {
//       "token": localStorage.getItem('token'),
//       "username": localStorage.getItem('username'),
//     }
//   })
// }


// 更新密码
export const updatePwdAPI = ({ username, newPassword, oldPassword }) => {
  // return request2({
  //   url: '/login/updatePwd',
  //   method: 'POST',
  //   data: {
  //     newPassword,
  //     oldPassword
  //   },
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/login/updatePwd',
    method: 'POST',
    data: {
      username,
      newPassword,
      oldPassword
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 核心筒算法设计
export const transformcorebuteAPI = (id) => {
  // return request2({
  //   url: '/corebute/transformcorebute?id=' + id,
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/corebute/transformcorebute?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 核心筒编辑的构件的修改
export const corebuteupdateAPI = ({ id, title, seismicIntensity, earthquakeGroup, sceneCategory, windPressure, groundRoughness, structureHeight, standardFloorStart, standardFloorEnd }, data) => {
  // return request2({
  //   url: '/corebute/corebuteupdate',
  //   method: 'POST',
  //   params: {
  //     id,
  //     userName,
  //     title,
  //     fileName,
  //     buteInputCsvUrl,
  //     buteInputConfigUrl,
  //     seismicIntensity,
  //     earthquakeGroup,
  //     sceneCategory,
  //     windPressure,
  //     groundRoughness,
  //     structureHeight,
  //     standardFloorStart,
  //     standardFloorEnd,
  //     createTime,
  //     showFileName
  //   },
  //   data,
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //     'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写
  //   }
  // })
  return request2({
    url: '/corebute/corebuteupdate',
    method: 'POST',
    params: {
      id,
      title,
      seismicIntensity,
      earthquakeGroup,
      sceneCategory,
      windPressure,
      groundRoughness,
      structureHeight,
      standardFloorStart,
      standardFloorEnd
    },
    data,
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      'Content-Type': 'multipart/form-data' // 传递图片 headers 里得这样写
    }
  })
}
// 根据id查看单个框架核心筒文件/corebute/findOneCorebute
export const findOneCorebuteAPI = (id) => {
  return request2({
    url: '/corebute/findOneCorebute?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// /corebute/downloadPng1  下载框架核心筒png1
export const downloadPng1API = (fileName) => {
  return request2({
    url: '/corebute/downloadPng1?fileName=' + fileName,
    method: 'GET',
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/octet-stream"
    }
  })
}

// /corebute/downloadPng1  下载框架核心筒png1
export const downloadPng2API = (fileName) => {
  return request2({
    url: '/corebute/downloadPng2?fileName=' + fileName,
    method: 'GET',
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/octet-stream"
    }
  })
}
// /corebute/downloadCsv1
export const downloadCsv1API = (fileName) => {
  return request2({
    url: '/corebute/downloadCsv1?fileName=' + fileName,
    method: 'GET',
    responseType: 'blob',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      "Content-Type": "application/octet-stream"
    }
  })
}
// 下载Png1
export const downPng1API = (fileName) => {
  // return request2({
  //   url: '/corebute/downloadPng1?fileName=' + fileName,
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/corebute/downloadPng1?fileName=' + fileName,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 管理员登录
export const adminLoginAPI = (loginId, password) => {
  return request2({
    url: '/login/adminlogin',
    method: 'POST',
    data: {
      loginId,
      password
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 模块使用趋势
export const moduleusagetrendsAPI = () => {
  // return request2({
  //   url: '/back/log/moduleusagetrends',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/log/moduleusagetrends',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 月用户增长
export const usergrowthAPI = () => {
  // return request2({
  //   url: '/back/log/usergrowth',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/log/usergrowth',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 模块销售额占比
export const salesratioAPI = () => {
  // return request2({
  //   url: '/back/log/salesratio',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/log/salesratio',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 用户列表查询
// back/user/list
export const userListAPI = () => {
  // return request2({
  //   url: '/back/user/list',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('adminToken'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/user/list',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}
//管理员手动修改用户列表产品时间
export const manualmodificationexpirationAPI = (data) => {
  // return request2({
  //   url: '/back/user/manualmodificationexpiration',
  //   method: 'POST',
  //   data,
  //   headers: {
  //     "token": localStorage.getItem('adminToken'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/user/manualmodificationexpiration',
    method: 'POST',
    data,
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 操作日志
export const logAPI = () => {
  // return request2({
  //   url: '/back/log/list',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/log/list',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 版本添加
export const versionAddAPI = (name, content) => {
  return request2({
    url: '/back/version/add?name=' + name + '&content=' + content,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 点击发布
export const insertQunfaDataAPI = (qunfaPeople, qunfaInfo) => {
  return request2({
    url: '/qunfa/insertQunfaData?qunfaPeople=' + qunfaPeople + '&qunfaInfo=' + qunfaInfo,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('token'),
      // "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 消息栏
export const msgAPI = (content, pageNum, pageSize) => {
  return request2({
    url: '/qunfa/qunfadata?content=' + content + '&pageNum='+ pageNum +'&pageSize=' + pageSize,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('token'),
      // "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 管理商品信息
export const productPriceAPI = () => {
  // return request2({
  //   url: '/back/productPrice/list',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('adminToken'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/productPrice/list',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 修改商品信息
// back/productPrice/update
export const updateProductAPI = ({ id, type, price, expiredDay, name, whiceOne }) => {
  // return request2({
  //   url: '/back/productPrice/update?id=' + id + '&type=' + type + '&price=' + price + '&expiredDay=' + expiredDay + '&name=' + name + '&whiceOne=' + whiceOne,
  //   method: 'POST',
  //   headers: {
  //     "token": localStorage.getItem('adminToken'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: '/back/productPrice/update?id=' + id + '&type=' + type + '&price=' + price + '&expiredDay=' + expiredDay + '&name=' + name + '&whiceOne=' + whiceOne,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 产品订单
export const productListAPI = () => {
  // return request2({
  //   url: 'product/list',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: 'product/list',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 生成订单
export const generateordersAPI = (ids) => {
  return request2({
    url: 'product/generateorders?ids=' + ids,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 个人支付列表
export const paylistAPI = (pageNum, pageSize) => {
  // return request2({
  //   url: 'order/payList',
  //   method: 'GET',
  //   headers: {
  //     "token": localStorage.getItem('token'),
  //     "username": localStorage.getItem('username'),
  //   }
  // })
  return request2({
    url: 'order/payList?pageNum=' + pageNum + '&pageSize=' + pageSize,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// /order/getOrderStatus
export const getOrderStatusAPI = (outTradeNo) => {
  return request2({
    url: '/order/getOrderStatus?outTradeNo=' + outTradeNo,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 删除未支付的订单
export const deleteUnpayOrderAPI = (payId) => {
  return request2({
    url: '/order/deleteUnpayOrder?payId=' + payId,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// /order/continuePay  继续支付
export const continuePayAPI = (payId) => {
  return request2({
    url: '/order/continuePay?payId=' + payId,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

//获取用户过期信息  /login/getuserExpired
export const getuserExpiredAPI = () => {
  return request2({
    url: '/login/getuserExpired',
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 历史版本查询
export const versionListAPI = (pageNum, pageSize) => {
  return request2({
    url: '/back/version/list?pageNum=' + pageNum + '&pageSize='+pageSize,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 历史版本删除
export const versionDeleteAPI = (versionId) => {
  return request2({
    url: '/back/version/delete?versionId=' + versionId,
    method: 'POST',
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 修改版本
export const versionUpdateAPI = ({ id, name, createTime, content, createBy }) => {
  return request2({
    url: '/back/version/update',
    method: 'POST',
    data: { id, name, createTime, content, createBy },
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}
// /back/version/getSystemOne
export const getSystemOneAPI = (id) => {
  return request2({
    url: '/back/version/getSystemOne?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('adminToken'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 规则自动生成
export const getNLPAPI = (nlpMessage) => {
  return request({
    url: '/AutoRule/transform?content=' + nlpMessage,
    method: 'GET',
  })
}

// 消息红点
export const doClickAPI = (all) => {
  return request2({
    url: '/qunfa/doClick?all=' + all,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 材料用量预测json文件上传
export const uploadUseageJson = (data) => {
  return request({
    url: '/cmq/upload',
    method: 'POST',
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
    
  })
}

// 材料用量
export const getusageAPI = ({numberOfLayers,floorHeight,basicDesignAcceleration,siteClassification,designEarthquakeGrouping},data) => {
  return request({
    url: '/upload',
    method: 'POST',
    params:{
      numberOfLayers,floorHeight,basicDesignAcceleration,siteClassification,designEarthquakeGrouping
    },
    data,
    headers:{
      'Content-Type': 'multipart/form-data'
    }
    
  })
}
// 新建剪力墙-梁-材料项目
export const addShearBeamCmq = ({ prjName}, data) => {
  return request2({
    url: '/wallBeamCmq/upload',
    method: 'POST',
    params: {
      prjName
    },
    data,
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
      'Content-Type': 'multipart/form-data'
    }
  })
}
// 分页查询剪力墙-梁-材料项目
export const queryShearBeamCmq = (pageNum, pageSize, content) => {
  return request2({
    url: '/wallBeamCmq/list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&content=' + content,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 单个查询剪力墙-梁-材料项目
export const findShearBeamCmq = (id) => {
  return request2({
    url: '/wallBeamCmq/findOne?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 删除剪力墙-梁项目
export const deleteShearBeamCmq = (id) => {
  return request2({
    url: '/wallBeamCmq/delete?id=' + id,
    method: 'GET',
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 设计查询剪力墙-梁-材料项目，一步到位接口，容易超时，分步设计接口在下面
export const designShearBeamCmq = (id, fileName) => {
  return request2({
    url: '/wallBeamCmq/inwall',
    method: 'GET',
    params: {
      id,
      fileName
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 创建剪力墙设计
export const creatShearwallDesign = (id, fileName) => {
  return request2({
    url: '/aidesign-info',
    method: 'POST',
    data: {
      "project_name": fileName,
      "structure_type": "shearwall",
      "algorithm": "GAN,GNN1,GNN2,Diffusion",
      "token": "aistructure",
      "priority": 0
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 查询剪力墙设计状态
export const shearwallDesignStatus = (id, projectName) => {
  return request2({
    url: '/aidesign-info',
    method: 'GET',
    params: {
      id,
      projectName
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}
// 更新剪力墙数据库
export const updateShearwallData = (id, projectName) => {
  return request2({
    url: '/wallBeamCmq/update_attempt_shearwall',
    method: 'GET',
    params: {
      id,
      projectName,
      "algorithm": "GAN,GNN1,GNN2,Diffusion",
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 梁设计
export const transformBeam = (shearWallId, project_name, version) => {
  return request2({
    url: '/beam/transform/transformBeam',
    method: 'POST',
    params: {
      project_name,
      'beamDesignVersion': 'Rule2', // 梁设计算法，目前固定为Rule2；
      version, // 剪力墙四种算法（传递这四个值的其中一个即可，GAN/GNN1/GNN2/Diffusion）；
      shearWallId,
      'copy': 'true'// 云平台调用，固定设为true；
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 墙梁优化接口
export const optswallbeam = (shearWallId, project_name, type, path) => {
  return request2({
    url: '/optswallbeam/transform',
    method: 'POST',
    params: {
      project_name, // 梁设计成功返回的project_name；
      type, // 剪力墙四种算法（传递这四个值的其中一个即可，GAN/GNN1/GNN2/Diffusion）；）；
      shearWallId,
      'copy': 'true',// 云平台调用，固定设为true；
      path // 梁设计返回的s2a2_json字段。
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 楼板设计接口
export const slabTransform = (shearWallId, project_name, type, path) => {
  return request2({
    url: '/slab/transform',
    method: 'POST',
    params: {
      project_name, // 墙梁优化成功返回的project_name；如果 7 墙梁优化失败，则使用6中梁设计返回的project_name；
      type, // 剪力墙四种算法（传递这四个值的其中一个即可，GAN/GNN1/GNN2/Diffusion）；）；
      shearWallId,
      'copy': 'true',// 云平台调用，固定设为true；
      path // 墙梁优化中返回的 s6a0_json 字段，如果墙梁优化出现失败，则直接使用 6 梁设计返回的s2a2_json字段执行后续操作
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 荷载设计接口

export const loadTransform = (shearWallId, project_name, type, path) => {
  return request2({
    url: '/load/transform',
    method: 'POST',
    params: {
      project_name, // 楼板设计成功返回的project_name；如果 8 楼板设计失败，则使用 7 中project_name字段，依次类推
      type, // 剪力墙四种算法（传递这四个值的其中一个即可，GAN/GNN1/GNN2/Diffusion）；）；
      shearWallId,
      'copy': 'true',// 云平台调用，固定设为true；
      path // 楼板设计成功返回的project_name；如果 8 楼板设计失败，则使用 7 中project_name字段，依次类推
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}

// 材料用量预测接口
export const cmqTransform = (shearWallId, project_name, type, path) => {
  return request2({
    url: '/cmq/transform',
    method: 'POST',
    params: {
      project_name, // 使用规则与上方荷载、楼板一致，使用顺序为：荷载结果--楼板结果--墙梁优化结果--梁结果，选择其project_name
      type, // 剪力墙四种算法（传递这四个值的其中一个即可，GAN/GNN1/GNN2/Diffusion）；）；
      shearWallId,
      'copy': 'true',// 云平台调用，固定设为true；
      path // 使用规则与上方荷载、楼板一致，使用顺序为：荷载结果--楼板结果--墙梁优化结果--梁结果，选择对应的_json字段
    },
    headers: {
      "token": localStorage.getItem('token'),
      "username": localStorage.getItem('username'),
    }
  })
}



export const monitorProjectList = (pageNum, pageSize, content) => {
  return request2({
    url: '/MonitorUI/find?',
    method: 'GET',
    params: {
      pageNum,
      pageSize,
      content
    }
  })
}

  // 新增邮箱注册方式
  export const registerEmail = (data) => {
    return request2({
      url: '/login/register',
      method: 'POST',
      data: data
    })
  }

    // 发送邮箱验证码
    export const sendEmailCode = (language, toEmailAddress) => {
      return request2({
        url: '/login/send-email-verify-code',
        method: 'POST',
        data: {
          language, toEmailAddress
        }
      })
    }

  // 邮箱验证码登录
  export const loginEmailCode = (data) => {
    return request2({
      url: '/login/email-login',
      method: 'POST',
      data: data
    })
  }

// 修改邮箱账号密码
export const updateEmailPassword = (data) => {
  return request2({
    url: '/login/forgetPassword',
    method: 'POST',
    data: data
  })
}
  
